// import api from '@/api/common';
import { upLoadCosFile } from '@/uitls/cos';

export default {
    methods: {
        /**
         * 上传图片
         * @param {File} file 图片
         * @param {*} folderName 要上传到的文件夹名
         * @returns {*}
        */
        handleUploadImg(file, folderName) {
            // const formData = new FormData();
            // formData.append('image', file.raw);
            // return api.upLoad(formData);
            return upLoadCosFile(file.raw, folderName);
        }
    }
};
