import COS from 'cos-js-sdk-v5';
import { v4 as uuidv4 } from 'uuid';
import { Message } from 'element-ui';
import cosAPI from '@/api/cos.js';

/**
 * upLoadCosFile
 * @param {*} file 上传文件
 * @param {*} folderName 要上传到的文件夹名
 * @returns {*}
 */
export async function upLoadCosFile(file, folderName = 'uploadImage') {
    return new Promise((resolve, reject) => {
        try {
            const cos = new COS({
                /**
                 * getAuthorization
                 * @param {*} options   options
                 * @param {*} callback  callback
                 */
                getAuthorization (options, callback) {
                    cosAPI.s3GetTempToken({ object_key: `${folderName}/*` }).then((response) => {
                        const data = response.data && response.data.data;
                        callback({
                            TmpSecretId: data.access_key,
                            TmpSecretKey: data.access_secret,
                            XCosSecurityToken: data.token,
                            // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
                            StartTime: data.exp_start_time, // 时间戳，单位秒，如：1580000000
                            ExpiredTime: data.exp_end_time // 时间戳，单位秒，如：1580000900
                            // ScopeLimit: true // 细粒度控制权限需要设为 true，会限制密钥只在相同请求时重复使用
                        });
                    }).catch((error) => error);
                    // console.log(error);
                }
            });
            cosAPI.s3Config().then((res) => {
                const suffix = file.name.split('.')[1];
                const id = uuidv4();
                const fileName = `${folderName}/${id}.${suffix}`;
                if (res && res.data.code === 10200) {
                    const _res = res.data.data;
                    cos.putObject(
                        {
                            Bucket: _res.bucket, /* 必须 */
                            Region: _res.region, /* 存储桶所在地域，必须字段 */
                            Key: fileName, /* 必须 */
                            Body: file // 上传文件对象
                        },
                        async (err, data) => {
                            if (data && data.statusCode === 200) {
                                resolve({
                                    ...data,
                                    url: `http://${data.Location}`
                                });
                            } else {
                                Message({ message: err, type: 'error' });
                                reject(err);
                            }
                        },
                    );
                } else {
                    Message({ message: '获取cos配置失败', type: 'error' });
                    reject(new Error());
                }
            });
        } catch (error) {
            Message({ message: error, type: 'error' });
            reject(error);
        }
    });
}
