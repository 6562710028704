import axios from '../uitls/require';

export default {
    /**
     * 获取Cos基础配置
     * @returns {*}
     */
    s3Config() {
        return axios.get('/admin/s3/config');
    },
    /**
     * 获取Cos上传临时token信息
     * @param {*} data  值
     * @returns {*}
     */
    s3GetTempToken(data) {
        return axios.post('/admin/s3/getTempToken', data);
    }
};
